<template>
  <div>
    <PreLoader v-if="isLoading" />

    <CustomBottomSheet
      refName="InstallmentScheduleTimeAdd"
      size="xl"
      :headerText="$t('Installments.add')"
      :headerIcon="installmentScheduleTime.icon"
      :clickToClose="false"
      :swipeAble="false"
      @opened="bottomSheetOpened()"
    >
      <InstallmentScheduleTimeForm
        v-if="!isLoading"
        id="add"
        :installmentScheduleTime="installmentScheduleTime"
        :installmentController="installmentController"
        v-on:submitForm="addInstallmentScheduleTime()"
        bottomSheetName="InstallmentScheduleTimeAdd"
        :submitName="$t('add')"
      />
    </CustomBottomSheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "./../../../../components/general/PreLoader.vue";
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import InstallmentScheduleTimeForm from "./InstallmentScheduleTimeForm.vue";
import { STATUS } from "./../../../../utils/constants";
// import { objectToFormData } from "./../../../../utils/functions";
import apiInstallmentScheduleTime from "./../../../../api/joiningApplications/installmentScheduleTimes";
import generalMixin from "./../../../../utils/generalMixin";
import { SCHEDULING_STATUS_TOKENS } from "./../../../../utils/constantLists";
import { getAllDebtsCustomized } from "./../../../../utils/dialogsOfApi";
import InstallmentController from "./../../../../models/joiningApplications/installmentScheduleTimes/InstallmentController";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    CustomBottomSheet,
    InstallmentScheduleTimeForm,
  },
  props: ["installmentScheduleTime", "userTokenOfDebt"],
  data() {
    return {
      installmentController: new InstallmentController(),
    };
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  async created() {},
  methods: {
    async bottomSheetOpened() {
      this.installmentScheduleTime.setInitialValue();
      await this.getDebtsDialog();
    },
    async getDebtsDialog() {
      this.isLoading = true;
      let params = {
        userToken: this.userTokenOfDebt,
        schedulingStatusTypeToken: SCHEDULING_STATUS_TOKENS.NotHaveSchedule,
      };
      let [userAllDebtsData, error] = await getAllDebtsCustomized(params);
      if (!error) {
        let userInfoData = userAllDebtsData[0].userInfoData;
        let installmentsData = await userAllDebtsData.map((d, i) => {
          let installmentScheduleTimesDataObj = {
            installmentMoney: d.debtMoneyStatistics.debtMoney,
            installmentMoneyWithCurrency:
              d.debtMoneyStatistics.debtMoneyWithCurrency,
            debtToken: d.debtToken,
            maxVal: d.debtMoneyStatistics.debtMoney,
            remainderMoney: d.debtMoneyStatistics.debtMoney,
            installmentScheduleTimePaidMoney:
              d.debtMoneyStatistics.installmentScheduleTimePaidMoney,
          };

          return {
            debtToken: d.debtToken,
            fullCode: d.fullCode,
            debtTitleCurrent: d.debtTitleCurrent,
            installmentMoney: d.debtMoneyStatistics.debtMoney,
            installmentMoneyWithCurrency:
              d.debtMoneyStatistics.debtMoneyWithCurrency,
            maxVal: d.debtMoneyStatistics.debtMoney,
            remainderMoney: d.debtMoneyStatistics.debtMoney,
            installmentScheduleTimePaidMoney:
              d.debtMoneyStatistics.installmentScheduleTimePaidMoney,
            isSelected: true,
            number: 1,
            index: i,
            posion: i + 1,
            installmentScheduleTimesData: [installmentScheduleTimesDataObj],
          };
        });

        let debtInstallmentsHandler = [];
        installmentsData.forEach((element) => {
          debtInstallmentsHandler.push(element);
          this.installmentScheduleTime.fillAddData({
            userInfoData: userInfoData,
            installmentsData: [element],
          });
        });
        this.installmentController.fillData({
          userInfoData: userInfoData,
          debtInstallmentsHandler: debtInstallmentsHandler,
          installmentsData: debtInstallmentsHandler,
        });
      }
      this.isLoading = false;
    },

    async addInstallmentScheduleTime() {
      this.isLoading = true;
      let installmentsData =
        await this.installmentController.installmentsData.map((element) => {
          return {
            debtToken: element.debtToken,
            installmentScheduleTimesData:
              element.installmentScheduleTimesData.map((i) => {
                return {
                  installmentMoney: i.installmentMoney,
                  installmentDateTime: i.installmentDateTime,
                  installmentScheduleTimeNotes: i.installmentScheduleTimeNotes,
                  installmentBatchTypeToken: i.installmentBatchTypeToken,
                  installmentScheduleTimePaidMoney:
                    i.installmentScheduleTimePaidMoney,
                  accountToken: i.accountToken,
                  paymentMethodToken: i.paymentMethodToken,
                };
              }),
          };
        });
      let modelAdd = await {
        userAuthorizeToken: this.userAuthorizeToken,
        installmentsData: installmentsData,
      };

      // let formData = objectToFormData(modelAdd);
      try {
        const response = await apiInstallmentScheduleTime.add(modelAdd);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$emit("refresh");
          this.closeBottomSheet("InstallmentScheduleTimeAdd");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
};
</script>
