export default class InstallmentScheduleTimesStatistics {
  constructor() {
    this.setInstallmentsStatisticsInitialValue();
  }
  setInstallmentsStatisticsInitialValue() {
    this.totalCount = 0;
    this.totalMoney = 0;
    this.totalInstallmentsMoney = 0;
    this.totalInstallmentsMoneyWithCurrency = "";
    this.totalInstallmentsMoneyText = "";
    this.totalPaidMoney = 0;
    this.totalPaidMoneyWithCurrency = "";
    this.totalPaidMoneyText = "";
    this.totalRemainderMoney = 0;
    this.totalRemainderMoneyWithCurrency = "";
    this.totalRemainderMoneyText = "";
    this.paidPercentage = 0;
    this.paidPercentageText = "";
    this.remainderPercentage = 0;
    this.remainderPercentageText = "";
    this.finishPercentage = 0;
    this.finishPercentageText = "";
    this.notFinishPercentage = 0;
    this.notFinishPercentageText = "";
    this.countDebtsFinish = 0;
    this.countDebtsNotFinish = 0;
  }
  fillInstallmentsStatisticsData(data) {
    if (data) {
      this.totalCount = data.totalCount ?? 0;
      this.totalMoney = data.totalMoney ?? 0;
      this.totalInstallmentsMoney = data.totalInstallmentsMoney ?? 0;
      this.totalInstallmentsMoneyWithCurrency =
        data.totalInstallmentsMoneyWithCurrency ?? "";
      this.totalInstallmentsMoneyText = data.totalInstallmentsMoneyText ?? "";
      this.totalPaidMoney = data.totalPaidMoney ?? 0;
      this.totalPaidMoneyWithCurrency = data.totalPaidMoneyWithCurrency ?? "";
      this.totalPaidMoneyText = data.totalPaidMoneyText ?? "";
      this.totalRemainderMoney = data.totalRemainderMoney ?? 0;
      this.totalRemainderMoneyWithCurrency =
        data.totalRemainderMoneyWithCurrency ?? "";
      this.totalRemainderMoneyText = data.totalRemainderMoneyText ?? "";
      this.paidPercentage = data.paidPercentage ?? 0;
      this.paidPercentageText = data.paidPercentageText ?? "";
      this.remainderPercentage = data.remainderPercentage ?? 0;
      this.remainderPercentageText = data.remainderPercentageText ?? "";
      this.finishPercentage = data.finishPercentage ?? 0;
      this.finishPercentageText = data.finishPercentageText ?? "";
      this.notFinishPercentage = data.notFinishPercentage ?? 0;
      this.notFinishPercentageText = data.notFinishPercentageText ?? "";
      this.countDebtsFinish = data.countDebtsFinish ?? 0;
      this.countDebtsNotFinish = data.countDebtsNotFinish ?? 0;
    } else {
      this.setInstallmentsStatisticsInitialValue();
    }
  }
}
