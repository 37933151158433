export default class InstallmentScheduleTimeDataClass {
  constructor(data) {
    if (!data) {
      this.setInitialValue();
    } else {
      this.fillData(data);
    }
  }
  setInitialValue() {
    this.installmentMoney = 0;
    this.installmentMoneyWithCurrency = "";
    this.debtToken = ""; //added by me
    this.maxVal = 0; //added by me
    this.installmentScheduleTimePaidMoney = 0; //Optional
    this.remainderMoney = 0; //added by me
    this.installmentDateTime = "";
    this.installmentScheduleTimeNotes = ""; //Optional
    this.installmentBatchTypeToken = ""; //Optional
    this.accountToken = ""; //must in paiy
    this.paymentMethodToken = ""; //must in paiy
  }
  fillData(data) {
    if (data) {
      this.installmentMoney = data.installmentMoney ?? 0;
      this.installmentMoneyWithCurrency =
        data.installmentMoneyWithCurrency ?? "";
      this.debtToken = data.debtToken ?? "";
      this.maxVal = data.maxVal ?? 0;
      this.installmentScheduleTimePaidMoney =
        data.installmentScheduleTimePaidMoney ?? 0; //Optional
      this.remainderMoney = data.remainderMoney ?? 0;
      this.installmentDateTime = data.installmentDateTime ?? "";
      this.installmentScheduleTimeNotes =
        data.installmentScheduleTimeNotes ?? ""; //Optional
      this.installmentBatchTypeToken = data.installmentBatchTypeToken ?? ""; //Optional
      this.accountToken = data.accountToken ?? ""; //must in paiy
      this.paymentMethodToken = data.paymentMethodToken ?? ""; //must in paiy
    } else {
      this.setInitialValue();
    }
  }
}
