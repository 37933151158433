// import DebtScheduleTime from "./DebtScheduleTime";
import DebtInstallmentsHandler from "./DebtInstallmentsHandler";
import InstallmentDataClass from "./InstallmentDataClass";
import InstallmentScheduleTimeDataClass from "./InstallmentScheduleTimeDataClass";

export default class InstallmentController {
  constructor(data) {
    if (data) this.fillData(data);
    else this.setInitialValue();
  }
  setInitialValue() {
    this.userInfoData = null;
    // this.debtScheduleTimes = [new DebtScheduleTime()];
    this.debtInstallmentsHandler = [new DebtInstallmentsHandler()];
    this.installmentsData = [new InstallmentDataClass()];
  }
  fillData(data) {
    if (data) {
      this.userInfoData = data.userInfoData ?? null;
      // if (data.debtScheduleTimes) {
      //   this.debtScheduleTimes = data.debtScheduleTimes.map((element) => {
      //     return new DebtScheduleTime(element);
      //   });
      // }
      if (data.debtInstallmentsHandler) {
        this.debtInstallmentsHandler = data.debtInstallmentsHandler.map(
          (element) => {
            return new DebtInstallmentsHandler(element);
          }
        );
      }
      if (data.installmentsData) {
        this.installmentsData = data.installmentsData.map((element) => {
          return new InstallmentDataClass(element);
        });
      }
    } else {
      this.setInitialValue();
    }
  }

  async updateHandler(token, item) {
    if (!item.isSelected) {
      this.removeItemFromList(token);
      this.updateItemInHandler(token, "isSelected", false);
      // this.updateItemInHandler(token, "number", 1);
    } else {
      // console.log("push", token, item);
    }
  }
  async updateItemInHandler(token, field, val) {
    this.debtInstallmentsHandler = await this.debtInstallmentsHandler.map(
      function (element) {
        if (element.debtToken == token) {
          element[field] = val;
        }
        return new DebtInstallmentsHandler(element);
      }
    );
  }

  async updateInstallmentsList(token, handler) {
    // if not selected remive from list
    if (!handler.isSelected) {
      this.removeItemFromList(token);
      this.updateItemInHandler(token, "installmentScheduleTimePaidMoney", 0);
      this.updateItemInHandler(
        token,
        "remainderMoney",
        handler.installmentMoney
      );
    } else {
      if (handler.number > 0) {
        let installmentScheduleTimesData = [];
        for (let i = 0; i < handler.number; i++) {
          let itemInstallmentScheduleTimesData =
            new InstallmentScheduleTimeDataClass();
          let maxVal = handler.installmentMoney / handler.number;
          itemInstallmentScheduleTimesData.installmentMoney = maxVal;
          itemInstallmentScheduleTimesData.remainderMoney = maxVal;
          // itemInstallmentScheduleTimesData.installmentScheduleTimePaidMoney =
          //   itemInstallmentScheduleTimesData.installmentMoney;
          itemInstallmentScheduleTimesData.debtToken = handler.debtToken;
          itemInstallmentScheduleTimesData.installmentMoneyWithCurrency =
            handler.installmentMoneyWithCurrency;

          // itemInstallmentScheduleTimesData.maxVal = handler.installmentMoney;
          itemInstallmentScheduleTimesData.maxVal =
            handler.installmentMoney - maxVal * i;
          installmentScheduleTimesData.push(itemInstallmentScheduleTimesData);
          this.updateItemInHandler(token, "maxVal", maxVal);
        }
        let handlerItem = {
          ...handler,
          installmentScheduleTimesData: installmentScheduleTimesData,
        };

        let isElementExist = await this.installmentsData.filter(
          (element) => element.debtToken == token
        ).length;

        if (isElementExist) {
          this.installmentsData = await this.installmentsData.map(function (
            element
          ) {
            if (element.debtToken == token) {
              element = new InstallmentDataClass(handlerItem);
            }
            return new InstallmentDataClass(element);
          });
        } else {
          this.installmentsData.splice(
            handler.index,
            isElementExist,
            new InstallmentDataClass(handlerItem)
          );
        }
      }
    }
  }
  async removeItemFromList(token) {
    this.installmentsData = await this.installmentsData.filter(
      (element) => element.debtToken != token
    );
  }
  // updateInnerMaxVal input
  async updateInnerMaxVal(indexItem) {
    let item = this.installmentsData[indexItem];
    item.installmentScheduleTimesData.forEach((element, indexS) => {
      let totalPaidToCurrent = item.installmentScheduleTimesData
        .slice(0, indexS)
        .reduce((a, b) => a + b.installmentMoney, 0);
      element.maxVal = item.installmentMoney - totalPaidToCurrent;
    });
  }
  async removeEmptyInner(indexItem) {
    let item = this.installmentsData[indexItem];
    item.installmentScheduleTimesData =
      await item.installmentScheduleTimesData.filter((e) => e.installmentMoney);
  }

  async installmentMoneyOfInnerListChanged(token, indexItem) {
    let item = this.installmentsData[indexItem];
    await this.removeEmptyInner(indexItem);
    let maxVal = 0;

    await item.installmentScheduleTimesData.every((st, i) => {
      if (maxVal >= item.installmentMoney) {
        item.installmentScheduleTimesData =
          item.installmentScheduleTimesData.slice(0, i);

        this.updateInnerMaxVal(indexItem);

        return false;
      }
      maxVal += st.installmentMoney;
      return true;
    });

    let remainToMax = item.installmentMoney - maxVal;
    if (remainToMax > 0) {
      let remainerItem = new InstallmentScheduleTimeDataClass();
      remainerItem.debtToken = item.debtToken;
      remainerItem.installmentMoney = remainToMax;
      remainerItem.remainderMoney = remainToMax;
      item.installmentScheduleTimesData.push(remainerItem);
    } else {
      let lastItem = item.installmentScheduleTimesData.pop();
      lastItem.installmentMoney += remainToMax;
      lastItem.installmentScheduleTimePaidMoney = lastItem.installmentMoney;
      lastItem.remainderMoney = 0;
      item.installmentScheduleTimesData.push(
        new InstallmentScheduleTimeDataClass(lastItem)
      );
    }

    await this.updateInnerMaxVal(indexItem);
    await this.removeEmptyInner(indexItem);

    let numOfInnerItems = item.installmentScheduleTimesData.length;
    let totalPaidOfInnerItems = item.installmentScheduleTimesData.reduce(
      (a, b) => a + b.installmentScheduleTimePaidMoney,
      0
    );
    let totalReminderOfInnerItems =
      item.installmentMoney - totalPaidOfInnerItems;

    item.installmentScheduleTimePaidMoney = totalPaidOfInnerItems;
    item.remainderMoney = totalReminderOfInnerItems;

    this.updateItemInHandler(token, "number", numOfInnerItems);
    this.updateItemInHandler(
      token,
      "installmentScheduleTimePaidMoney",
      totalPaidOfInnerItems
    );
    this.updateItemInHandler(
      token,
      "remainderMoney",
      totalReminderOfInnerItems
    );
  }

  async paidMoneyOfInnerListChanged(token) {
    let totalPaidOfInnerItems = null;
    let totalReminderOfInnerItems = null;

    this.installmentsData = await this.installmentsData.map(function (element) {
      if (element.debtToken == token) {
        totalPaidOfInnerItems = element.installmentScheduleTimesData.reduce(
          (a, b) => a + b.installmentScheduleTimePaidMoney,
          0
        );
        totalReminderOfInnerItems =
          element.installmentMoney - totalPaidOfInnerItems;
        element.installmentScheduleTimePaidMoney = totalPaidOfInnerItems;
        element.remainderMoney = totalReminderOfInnerItems;
      }
      return new InstallmentDataClass(element);
    });

    this.updateItemInHandler(
      token,
      "installmentScheduleTimePaidMoney",
      totalPaidOfInnerItems
    );
    this.updateItemInHandler(
      token,
      "remainderMoney",
      totalReminderOfInnerItems
    );
  }
}
