import GeneralModelResponse from "./../../general/GeneralModelResponse";
import InstallmentScheduleTimesStatistics from "./InstallmentScheduleTimesStatistics";

export default class InstallmentScheduleTimeGeneralModel extends GeneralModelResponse {
  constructor() {
    super();
  }
  setGeneralModelInitialValue() {
    this.setGeneralModelResponseInitialValue();
    this.installmentScheduleTimeStatistics =
      new InstallmentScheduleTimesStatistics();
  }
  fillGeneralModelData(data) {
    if (data) {
      this.fillGeneralModelResponseData(data);
      this.installmentScheduleTimeStatistics.fillInstallmentsStatisticsData(
        data.installmentScheduleTimeStatistics
      );
    } else {
      this.setGeneralModelInitialValue();
    }
  }
}
