<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form autocomplete="off">
      <div class="my-card">
        <div
          v-if="installmentController.installmentsData.length == 0"
          class="my-card-no-content"
        >
          {{ $t("general.thereAreNoDetails") }}
        </div>
        <template v-else>
          <!--Start debtInstallmentsHandler -->
          <div class="row">
            <div class="col-md-12">
              <div class="container-collapse-with-profile">
                <b-button
                  v-b-toggle.DebtScheduleTimes
                  class="btn btn-lg btn-collapse"
                >
                  <div class="profile-container">
                    <img
                      :src="
                        fullPathFileFromServer(
                          installmentController.userInfoData.userImagePath,
                          defaultImg
                        )
                      "
                      :onerror="`this.src='${defaultImg}'`"
                      class="icon-lg profile-icon"
                    />
                    <div class="profile-text">
                      <span class="main-text">
                        {{
                          installmentController.userInfoData.userNameCurrent
                        }}</span
                      >
                      <span class="lead-text">{{
                        installmentController.userInfoData.fullCode
                      }}</span>
                    </div>
                  </div>
                  <div class="collapse-icon">
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </b-button>
                <b-collapse id="DebtScheduleTimes">
                  <table class="my-table no-padding">
                    <thead>
                      <tr>
                        <th rowspan="2">#</th>
                        <th rowspan="2">#</th>

                        <th colspan="4" class="cell-lg">
                          {{ $t("Debts.data") }}
                        </th>
                        <th rowspan="2">
                          {{ $t("general.scheduleTimesNumber") }}
                        </th>
                      </tr>
                      <tr>
                        <th>{{ $t("general.code") }}</th>
                        <th>{{ $t("general.value") }}</th>
                        <th>{{ $t("general.paid") }}</th>
                        <th>{{ $t("general.remainding") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          handler, indexDebtTime
                        ) in installmentController.debtInstallmentsHandler"
                        :key="indexDebtTime"
                      >
                        <td>{{ indexDebtTime + 1 }}</td>
                        <td>
                          <input
                            :disabled="onlyOneSelected && handler.isSelected"
                            type="checkbox"
                            v-model="handler.isSelected"
                            :value="handler.isSelected"
                            @click="
                              handler.isSelected = !handler.isSelected;
                              handlerChanged(handler.debtToken, handler);
                            "
                            class="checkbox"
                          />
                        </td>
                        <td>{{ handler.fullCode }}</td>
                        <td>{{ handler.installmentMoney }}</td>
                        <td>{{ handler.installmentScheduleTimePaidMoney }}</td>
                        <td>{{ handler.remainderMoney }}</td>
                        <td class="table-icon">
                          <CustomInputInt
                            :withOutDesign="true"
                            :maxValueStatus="true"
                            :maxValue="100"
                            :maxlength="3"
                            :id="`${id}[${indexDebtTime}][number]`"
                            :value="handler.number"
                            v-on:changeValue="
                              handler.number = $event;
                              handlerChanged(handler.debtToken, handler);
                            "
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-collapse>
              </div>
            </div>
          </div>
          <!--End debtInstallmentsHandler -->

          <div
            class="my-card"
            v-for="(item, index) in installmentController.installmentsData"
            :key="index"
          >
            <span class="my-card-title">{{ item.posion }}</span>

            <span
              v-if="installmentController.installmentsData.length > 1"
              class="remove-slice-container"
            >
              <button
                @click="
                  item.isSelected = false;
                  listChanged(item.debtToken, item);
                "
                type="button"
                class="btn btn-remove-slice"
              >
                ×
              </button>
            </span>
            <div class="row">
              <div class="statistics col-12">
                <b-button
                  v-b-toggle="`${item.debtToken}${index}`"
                  class="btn btn-lg btn-collapse arrow-end"
                >
                  <span
                    >{{ $t("general.code") }}{{ `: ` }}{{ item.fullCode
                    }}{{ ` - ` }} {{ $t("general.value") }}{{ `: `
                    }}{{ item.installmentMoneyWithCurrency }}{{ ` - `
                    }}{{ $t("general.paid") }}{{ `: `
                    }}{{ item.installmentScheduleTimePaidMoney }}{{ ` - `
                    }}{{ $t("general.remainding") }}{{ `: `
                    }}{{ item.remainderMoney }}</span
                  >
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </b-button>
                <b-collapse :id="`${item.debtToken}${index}`">
                  <table class="my-table table no-padding">
                    <thead class="thead-dark">
                      <tr>
                        <th>#</th>
                        <th class="cell-lg">
                          {{ $t("InstallmentBatchTypes.select") }}
                        </th>
                        <th>{{ $t("general.value") }}</th>
                        <th class="cell-xl">{{ $t("pay") }}</th>
                        <th class="cell-lg">
                          {{ $t("actionsData.dateTime") }}
                        </th>
                        <th>{{ $t("general.remainding") }}</th>
                        <th>{{ $t("notes") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          scheduleTime, indexS
                        ) in item.installmentScheduleTimesData"
                        :key="indexS"
                      >
                        <td>{{ indexS + 1 }}</td>
                        <td>
                          <CustomSelectBox
                            :isDisabled="!scheduleTime.installmentMoney"
                            :id="`${id}[${indexS}][installmentBatchTypeToken]`"
                            :value="scheduleTime.installmentBatchTypeToken"
                            :options="installmentBatchTypeTokenOptions"
                            v-on:changeValue="
                              scheduleTime.installmentBatchTypeToken = $event
                            "
                            :title="$t('InstallmentBatchTypes.select')"
                            :imgName="'installmentBatchTypes.svg'"
                            :withOutDesign="true"
                            :hideImg="true"
                          />
                        </td>
                        <td>
                          <CustomInputFloat
                            :isDisabled="isListHasOneItem(item)"
                            :maxValueStatus="true"
                            :maxValue="scheduleTime.maxVal"
                            :id="`${id}[${indexS}][installmentMoney]`"
                            :value="scheduleTime.installmentMoney"
                            v-on:changeValue="
                              scheduleTime.installmentMoney = $event;
                              scheduleTime.installmentScheduleTimePaidMoney =
                                $event;
                              scheduleTime.remainderMoney =
                                $event -
                                scheduleTime.installmentScheduleTimePaidMoney;
                              installmentMoneyOfInnerListChanged(
                                scheduleTime.debtToken,
                                index
                              );
                              paidMoneyOfInnerListChanged(
                                scheduleTime.debtToken
                              );
                            "
                            :withOutDesign="true"
                          />
                        </td>
                        <td>
                          <CustomInputFloat
                            :isDisabled="!scheduleTime.installmentMoney"
                            :maxValueStatus="true"
                            :maxValue="scheduleTime.installmentMoney"
                            :id="`${id}[${indexS}][installmentScheduleTimePaidMoney]`"
                            :value="
                              scheduleTime.installmentScheduleTimePaidMoney
                            "
                            v-on:changeValue="
                              scheduleTime.installmentScheduleTimePaidMoney =
                                $event;
                              scheduleTime.remainderMoney =
                                scheduleTime.installmentMoney - $event;
                              paidMoneyOfInnerListChanged(
                                scheduleTime.debtToken
                              );
                            "
                            :withOutDesign="true"
                          />
                          <CustomSelectBox
                            v-if="scheduleTime.installmentScheduleTimePaidMoney"
                            :withOutDesign="true"
                            :id="`${id}[${indexS}]-accountToken`"
                            :value="scheduleTime.accountToken"
                            :options="accountTokenOptions"
                            v-on:changeValue="
                              scheduleTime.accountToken = $event
                            "
                            :title="$t('Accounts.select')"
                            :imgName="'accounts.svg'"
                            :hideImg="true"
                          />
                          <CustomSelectBox
                            v-if="scheduleTime.installmentScheduleTimePaidMoney"
                            :withOutDesign="true"
                            :id="`${id}[${indexS}]-paymentMethodToken`"
                            :value="scheduleTime.paymentMethodToken"
                            :options="paymentMethodTokenOptions"
                            v-on:changeValue="
                              scheduleTime.paymentMethodToken = $event
                            "
                            :title="$t('PaymentMethods.select')"
                            :imgName="'paymentMethods.svg'"
                            :hideImg="true"
                          />
                        </td>
                        <td>
                          <DateTimePicker
                            :isDisabled="!scheduleTime.installmentMoney"
                            :id="`${id}[${indexS}][installmentDateTime]`"
                            type="dateTime"
                            :value="scheduleTime.installmentDateTime"
                            :title="$t('actionsData.dateTime')"
                            v-on:changeValue="
                              scheduleTime.installmentDateTime = $event.dateTime
                            "
                            :language="language"
                            :withOutDesign="true"
                          />
                        </td>
                        <td>
                          {{ scheduleTime.remainderMoney }}
                        </td>

                        <td>
                          <TextArea
                            :isDisabled="!scheduleTime.installmentMoney"
                            :withOutDesign="true"
                            :id="`${id}[${indexS}][installmentNotes]`"
                            :value="scheduleTime.installmentNotes"
                            v-on:changeValue="
                              scheduleTime.installmentNotes = $event
                            "
                            :title="$t('notes')"
                            :imgName="'notes.svg'"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-collapse>
              </div>
            </div>
          </div>
        </template>

        <!-- <div class="add-slice-container">
          <button
            @click="addSlice"
            type="button"
            class="btn btn-add-slice"
            :title="$t('addSlice')"
          >
            +
          </button>
        </div> -->
      </div>

      <div class="form-actions">
        <div class="icon-submit" @click.prevent="submitForm">
          <img src="@/assets/images/check-icon.svg" :title="submitName" />
        </div>
        <div
          @click.prevent
          class="icon-cancel"
          v-b-modal="`ConfirmCloseSheet-${bottomSheetName}`"
        >
          <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  fullPathFileFromServer,
  // bottomSheetScrollToTop,
} from "./../../../../utils/functions";
// import InstallmentDataClass from "./../../../../models/joiningApplications/installmentScheduleTimes/InstallmentDataClass";
import defaultImg from "@/assets/images/students.svg";
import PreLoader from "./../../../../components/general/PreLoader.vue";
import CustomInputInt from "./../../../../components/general/CustomInputInt.vue";
import CustomInputFloat from "./../../../../components/general/CustomInputFloat.vue";
import DateTimePicker from "./../../../../components/general/DateTimePicker.vue";
import CustomSelectBox from "./../../../../components/general/CustomSelectBox.vue";
// import InstallmentScheduleTimesInfoDataComponet from "./InstallmentScheduleTimesInfoDataComponet.vue";
import TextArea from "./../../../../components/general/TextArea.vue";
import { hasInstallmentDeleteImage } from "./../../../../utils/privilegeHelper";
import { checkPrivilege, getLanguage } from "./../../../../utils/functions";
import generalMixin from "./../../../../utils/generalMixin";
import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
import {
  // USER_TYPE,
  SCHEDULING_STATUS_TOKENS,
} from "./../../../../utils/constantLists";
import {
  getInstallmentBatchTypesDialog,
  getAccountsDialog,
  getPaymentMethodsDialog,
  // getUsersDialog,
  getDebtsDialog,
} from "./../../../../utils/dialogsOfApi";

export default {
  mixins: [generalMixin, validationMixin],
  components: {
    PreLoader,
    CustomInputInt,
    CustomInputFloat,
    CustomSelectBox,
    DateTimePicker,
    TextArea,
    // InstallmentScheduleTimesInfoDataComponet,
  },
  props: {
    installmentScheduleTime: {
      type: Object,
    },
    installmentController: {
      type: Object,
    },
    submitName: {
      type: String,
    },
    id: {
      type: String,
      default: "id",
    },
    deleteFileStatus: {
      type: Boolean,
      default: false,
    },
    bottomSheetName: {
      type: String,
      default: "",
    },
  },
  validations: {
    installment: {},
  },
  data() {
    return {
      language: getLanguage(),
      // onlyOneSelected: "",
      defaultImg,
      debtUserTokenOptions: [],
      debtTokensOptions: [],
      accountTokenOptions: [],
      paymentMethodTokenOptions: [],
      installmentBatchTypeTokenOptions: [],
    };
  },
  computed: {
    onlyOneSelected() {
      return this.installmentController.debtInstallmentsHandler.filter(
        (h) => h.isSelected
      ).length == 1
        ? true
        : false;
    },
  },
  watch: {
    // "installmentController.debtInstallmentsHandler": function (val) {
    //   // console.log("debtInstallmentsHandler changed", val);
    //   // let atLeatOneSelected = val.some((h) => h.isSelected);
    //   let onlyOneSelected =
    //     val.filter((h) => h.isSelected).length == 1 ? true : false;
    //   console.log("atLeatOneSelected", onlyOneSelected);
    // },
  },
  async created() {
    if (this.id == "add") {
      await this.getDialogs();
    }
  },
  methods: {
    checkPrivilege,
    hasInstallmentDeleteImage,
    fullPathFileFromServer,
    async getDialogs() {
      await this.getInstallmentBatchTypesDialog();
      await this.getAccountsDialog();
      await this.getPaymentMethodsDialog();
      // await this.getStudentsDialog();
      await this.getDebtsDialog();
    },
    async getInstallmentBatchTypesDialog() {
      this.isLoading = true;
      this.installmentBatchTypeTokenOptions =
        await getInstallmentBatchTypesDialog();
      this.isLoading = false;
    },
    async getAccountsDialog() {
      this.isLoading = true;
      this.accountTokenOptions = await getAccountsDialog();
      this.isLoading = false;
    },
    async getPaymentMethodsDialog() {
      this.isLoading = true;
      this.paymentMethodTokenOptions = await getPaymentMethodsDialog();
      this.isLoading = false;
    },
    async getDebtsDialog(userToken = "") {
      this.isLoading = true;
      let params = {
        userToken: userToken,
        schedulingStatusTypeToken: SCHEDULING_STATUS_TOKENS.NotHaveSchedule,
      };
      this.debtTokensOptions = await getDebtsDialog(params);
      this.isLoading = false;
    },

    handlerChanged(token, handler) {
      // console.log("handlerChanged", token, handler);
      this.installmentController.updateInstallmentsList(token, handler);
    },
    listChanged(token, item) {
      // console.log("listChanged", token, item);
      this.installmentController.updateHandler(token, item);
    },
    installmentMoneyOfInnerListChanged(token, indexItem) {
      setTimeout(() => {
        this.installmentController.installmentMoneyOfInnerListChanged(
          token,
          indexItem
        );
      }, "1000");
    },
    paidMoneyOfInnerListChanged(token) {
      setTimeout(() => {
        this.installmentController.paidMoneyOfInnerListChanged(token);
      }, "1000");
    },

    isListHasOneItem(list) {
      return list.installmentScheduleTimesData.length == 1;
    },

    async submitForm() {
      this.$emit("submitForm");
    },
  },
};
</script>
