<template>
  <div v-if="installmentScheduleTimesData" class="table-container">
    <!-- <div
      class=""
      v-for="(installment, index) in installmentScheduleTimesData"
      :key="index"
    ></div> -->
    <div class="">
      <table class="my-table mt-2">
        <thead>
          <tr>
            <th rowspan="2">#</th>
            <th colspan="4">{{ $t("Installments.dataScheduleTime") }}</th>
            <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
          </tr>
          <tr>
            <th class="cell-lg">{{ $t("general.accrualTime") }}</th>
            <th>{{ $t("general.value") }}</th>
            <th>{{ $t("general.paid") }}</th>
            <th>{{ $t("general.remainding") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(installment, index) in installmentScheduleTimesData"
            :key="index"
          >
            <td
              :class="getClass(installment).class"
              :title="getClass(installment).title"
            >
              {{ ++index + filterData.currentIndex }}
            </td>

            <td>
              {{
                formateDateTimeLang(
                  installment.installmentDate,
                  installment.installmentTime
                )
              }}
            </td>
            <td>
              {{ isDataExist(installment.installmentMoneyWithCurrency) }}
            </td>
            <td>
              {{ isDataExist(installment.paidMoneyWithCurrency) }}
            </td>
            <td>
              {{ isDataExist(installment.remainderMoneyWithCurrency) }}
            </td>

            <td
              v-if="
                checkPrivilege(hasInstallmentPaymentAdd()) &&
                installment.remainderMoney > 0
              "
              class="fmenu-item-container"
            >
              <FloatingMenu>
                <li>
                  <button
                    @click="addInstallmentPayment(installment)"
                    :title="$t('pay')"
                  >
                    <img class="item-img-table" src="@/assets/images/pay.svg" />
                  </button>
                </li>
                <li
                  v-if="
                    checkPrivilege(hasInstallmentPaymentRefund()) &&
                    installment.paidMoney > 0
                  "
                >
                  <button
                    @click="refundInstallmentPayment(installment)"
                    :title="$t('refund')"
                  >
                    <img src="@/assets/images/refund.svg" />
                  </button>
                </li>
              </FloatingMenu>
            </td>
            <td
              v-else-if="
                checkPrivilege(hasInstallmentPaymentRefund()) &&
                installment.paidMoney > 0
              "
            >
              <FloatingMenu>
                <li
                  v-if="
                    checkPrivilege(hasInstallmentPaymentRefund()) &&
                    installment.paidMoney > 0
                  "
                >
                  <button
                    @click="refundInstallmentPayment(installment)"
                    :title="$t('refund')"
                  >
                    <img src="@/assets/images/refund.svg" />
                  </button>
                </li>
              </FloatingMenu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  formateDateTimeLang,
  fullPathFileFromServer,
} from "./../../../../utils/functions";
import { CANCEL_TYPE_TOKENS } from "./../../../../utils/constantLists";
import {
  hasInstallmentPaymentAdd,
  hasInstallmentPaymentRefund,
} from "./../../../../utils/privilegeHelper";
import FloatingMenu from "./../../../../components/general/FloatingMenu.vue";
// import DataLabelGroup from "./../../../../components/general/DataLabelGroup.vue";
import generalMixin from "./../../../../utils/generalMixin";

export default {
  name: "InstallmentsTable",
  mixins: [generalMixin],
  components: {
    FloatingMenu,
    // DataLabelGroup,
  },
  props: ["installmentScheduleTimesData", "filterData", "defaultImg"],
  watch: {},
  created() {},
  data() {
    return {
      CANCEL_TYPE_TOKENS,
      showDebetInfoStatus: [],
      keyRender: "",
    };
  },
  computed: {
    showDebetInfo() {
      return this.showDebetInfoStatus;
    },
  },
  methods: {
    getClass(installment) {
      let className = "";
      let title = "";
      // paid all
      if (installment.remainderMoney == 0) {
        className = "status-green";
        title = this.$t("general.paid");
      } else if (installment.remainderMoney > 0) {
        className = "status-yellow";
        title = this.$t("general.remainding");
      }

      return {
        class: className,
        title: title,
      };
    },
    setInstallmentScheduleTimeData(installment) {
      this.$emit("setInstallmentScheduleTimeData", installment);
    },
    addInstallmentPayment(scheduleTime) {
      this.$emit("addInstallmentPayment", scheduleTime);
    },
    refundInstallmentPayment(scheduleTime) {
      this.$emit("refundInstallmentPayment", scheduleTime);
    },

    checkPrivilege,
    isDataExist,
    formateDateTimeLang,
    fullPathFileFromServer,
    hasInstallmentPaymentAdd,
    hasInstallmentPaymentRefund,
  },
};
</script>
