// import InstallmentScheduleTimeDataClass from "./InstallmentScheduleTimeDataClass";
// import DebtScheduleTime from "./DebtScheduleTime";

export default class DebtInstallmentsHandler {
  constructor(data) {
    if (!data) {
      this.setInitialValue();
    } else {
      this.fillData(data);
    }
  }
  setInitialValue() {
    this.debtToken = "";
    this.isSelected = true;
    this.fullCode = "";
    this.debtTitleCurrent = "";
    this.installmentMoney = 0;
    this.installmentMoneyWithCurrency = "";
    this.maxVal = 0;
    this.installmentScheduleTimePaidMoney = 0;
    this.remainderMoney = 0;
    this.number = 1;
    this.index = null;
    this.posion = null;
    // this.installmentScheduleTimesData = [
    //   new InstallmentScheduleTimeDataClass(),
    // ];
  }
  fillData(data) {
    if (data) {
      this.debtToken = data.debtToken ?? "";
      this.isSelected = data.isSelected ?? true;
      this.fullCode = data.fullCode ?? "";
      this.debtTitleCurrent = data.debtTitleCurrent ?? "";
      this.installmentMoney = data.installmentMoney ?? 0;
      this.installmentMoneyWithCurrency =
        data.installmentMoneyWithCurrency ?? "";
      this.maxVal = data.maxVal ?? 0;
      this.installmentScheduleTimePaidMoney =
        data.installmentScheduleTimePaidMoney ?? 0;
      this.remainderMoney = data.remainderMoney ?? 0;
      this.number = data.number ?? 1;
      this.index = data.index ?? null;
      this.posion = data.posion ?? null;
      // if (data.installmentScheduleTimesData) {
      //   this.installmentScheduleTimesData =
      //     data.installmentScheduleTimesData.map((element) => {
      //       return new InstallmentScheduleTimeDataClass(element);
      //     });
      // }
    } else {
      this.setInitialValue();
    }
  }
}
