import BaseEntityModel from "./../../general/BaseEntityModel";
import defaultImg from "@/assets/images/installments.svg";
import InstallmentDataClass from "./InstallmentDataClass";

export default class InstallmentScheduleTime extends BaseEntityModel {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setInitialValueBaseEntityModel();
    this.installmentScheduleTimeToken = "";
    this.installmentScheduleTimeNameCurrent = "";
    this.installmentScheduleTimeNotes = "";
    this.installmentDateTime = "";
    this.installmentDateTimeCustomized = "";
    this.installmentDate = "";
    this.installmentTime = "";
    this.installmentMoney = "";
    this.installmentMoneyText = "";
    this.installmentMoneyWithCurrency = "";
    this.paidMoney = "";
    this.paidMoneyText = "";
    this.paidMoneyWithCurrency = "";
    this.remainderMoney = "";
    this.remainderMoneyText = "";
    this.remainderMoneyWithCurrency = "";
    this.finishPercentage = "";
    this.finishPercentageText = "";
    this.debtToken = "";
    this.debtInfoData = null;
    this.installmentBatchTypeToken = "";
    this.installmentBatchTypeInfoData = null;
    // added by me for add
    this.userInfoData = null;
    this.installmentsData = [new InstallmentDataClass()];
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModel(data);
      this.installmentScheduleTimeToken =
        data.installmentScheduleTimeToken ?? "";
      this.installmentScheduleTimeNameCurrent =
        data.installmentScheduleTimeNameCurrent ?? "";
      this.installmentScheduleTimeNotes =
        data.installmentScheduleTimeNotes ?? "";
      this.installmentDateTime = data.installmentDateTime ?? "";
      this.installmentDateTimeCustomized =
        data.installmentDateTimeCustomized ?? "";
      this.installmentDate = data.installmentDate ?? "";
      this.installmentTime = data.installmentTime ?? "";
      this.installmentMoney = data.installmentMoney ?? "";
      this.installmentMoneyText = data.installmentMoneyText ?? "";
      this.installmentMoneyWithCurrency =
        data.installmentMoneyWithCurrency ?? "";
      this.paidMoney = data.paidMoney ?? "";
      this.paidMoneyText = data.paidMoneyText ?? "";
      this.paidMoneyWithCurrency = data.paidMoneyWithCurrency ?? "";
      this.remainderMoney = data.remainderMoney ?? "";
      this.remainderMoneyText = data.remainderMoneyText ?? "";
      this.remainderMoneyWithCurrency = data.remainderMoneyWithCurrency ?? "";
      this.finishPercentage = data.finishPercentage ?? "";
      this.finishPercentageText = data.finishPercentageText ?? "";
      this.debtToken = data.debtToken ?? "";
      this.debtInfoData = data.debtInfoData ?? null;
      this.installmentBatchTypeToken = data.installmentBatchTypeToken ?? "";
      this.installmentBatchTypeInfoData =
        data.installmentBatchTypeInfoData ?? null;
    } else {
      this.setInitialValue();
    }
  }
  fillAddData(data) {
    if (data) {
      this.userInfoData = data.userInfoData ?? null;
      if (data.installmentsData) {
        this.installmentsData = data.installmentsData.map((element) => {
          return new InstallmentDataClass(element);
        });
      }
    } else {
      // added by me for add
      this.userInfoData = null;
      this.installmentsData = [new InstallmentDataClass()];
    }
  }
}
